import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Skeleton,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  EmailOutlined as EmailIcon,
  PhoneOutlined as PhoneIcon,
  ContentCopy as ContentCopyIcon,
  HistoryOutlined as HistoryIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import theme from "@/utils/theme";
import { ParticipantResponse } from "@/domain/participant.interface";
import ProfileMenu from "@/ui/menus/clients/ProfileMenu";
import ClientNameCard from "./ProfileName";

interface ProfileCardsProps {
  participantResponse: ParticipantResponse;
  isLoadingParticipant: boolean;
  handleCopy: (text: string, message: string) => void;
  handleOpenOfficersModal: () => void;
  handleOpenSupportStaffModal: () => void;
  handleOpenInHouseProviderModal: () => void;
  handleOpenEditModal: () => void;
  handleOpenDismissModal: () => void;
}

const ProfileCards: React.FC<ProfileCardsProps> = ({
  participantResponse,
  isLoadingParticipant,
  handleCopy,
  handleOpenOfficersModal,
  handleOpenSupportStaffModal,
  handleOpenInHouseProviderModal,
  handleOpenEditModal,
  handleOpenDismissModal,
}) => {
  const { t } = useTranslation("clients");
  const { participant, lastEnrollment } = participantResponse;
  const primaryOfficer = lastEnrollment?.primaryOfficer;
  const supportStaff = lastEnrollment?.supportStaffsInfo;
  const internalProviders = lastEnrollment?.internalProvidersInfo;
  const tempShowAuditLogButton = false;
  return (
    <>
      <ClientNameCard
        isLoading={isLoadingParticipant}
        firstName={participant?.firstName}
        lastName={participant?.lastName}
      />
      <Grid container mt={0} spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            p={3}
            sx={{
              boxShadow: 1,
              borderRadius: 2,
              bgcolor: "background.paper",
              height: "100%",
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography
                color={theme.palette.textMain.dark2}
                variant="headlineSmall"
              >
                {t("client_profile.client_information")}
              </Typography>
              <Button
                startIcon={<EditIcon />}
                variant="contained"
                disabled={isLoadingParticipant}
                onClick={handleOpenEditModal}
              >
                {t("client_profile.edit")}
              </Button>
            </Box>
            <Box mt={-1}>
              <Typography
                textTransform={"uppercase"}
                color={theme.palette.textMain.light2}
                variant="bodyMedium"
              >
                {t("client_profile.case_number")}{" "}
                {lastEnrollment?.caseNumbers || "N/A"}
              </Typography>
            </Box>
            <Box mt={2}>
              {isLoadingParticipant ? (
                <Skeleton variant="rectangular" width={300} height={50} />
              ) : (
                <>
                  <Box>
                    <Typography variant="bodyLarge">
                      <strong>{t("client_profile.last_login")}</strong>{" "}
                      {participant?.mobileUser?.lastLoginDate
                        ? dayjs(participant?.mobileUser?.lastLoginDate).format(
                            "DD MMM YYYY, hh:mm A"
                          )
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="bodyLarge">
                      <strong>{t("client_profile.status")}</strong>{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {lastEnrollment?.status?.toLocaleLowerCase() || "N/A"}
                      </span>
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    mt={3}
                    justifyContent="flex-start"
                  >
                    {participant?.email ? (
                      <Grid item xs={12} sm="auto">
                        <Box
                          border={1}
                          borderRadius={2}
                          borderColor={theme.palette.outline.light2}
                          display="flex"
                          alignItems="center"
                        >
                          <EmailIcon
                            style={{
                              fontSize: "1.2rem",
                              marginRight: ".5rem",
                              marginLeft: ".5rem",
                            }}
                          />
                          <Typography variant="labelMedium">
                            {participant?.email}
                          </Typography>
                          <IconButton
                            data-testid="copy_email"
                            onClick={() =>
                              handleCopy(
                                participant?.email || "",
                                t("client_profile.email_copied")
                              )
                            }
                          >
                            <ContentCopyIcon style={{ fontSize: "1.2rem" }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {participant?.phoneNumber ? (
                      <Grid item xs={12} sm="auto">
                        <Box
                          border={1}
                          borderRadius={2}
                          borderColor={theme.palette.outline.light2}
                          display="flex"
                          alignItems="center"
                        >
                          <PhoneIcon
                            style={{
                              fontSize: "1.2rem",
                              marginRight: ".5rem",
                              marginLeft: ".5rem",
                            }}
                          />
                          <Typography variant="labelMedium">
                            {participant?.phoneNumber}
                          </Typography>
                          <IconButton
                            data-testid="copy_phone"
                            onClick={() =>
                              handleCopy(
                                participant?.phoneNumber || "",
                                t("client_profile.phone_copied")
                              )
                            }
                          >
                            <ContentCopyIcon style={{ fontSize: "1.2rem" }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            p={3}
            sx={{
              boxShadow: 1,
              borderRadius: 2,
              bgcolor: "background.paper",
              height: "100%",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color={theme.palette.textMain.dark2}
                variant="headlineSmall"
                sx={{ flexGrow: 1 }}
              >
                {t("client_profile.parca_information")}
              </Typography>
              {tempShowAuditLogButton ? (
                <Tooltip title="Audit log">
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      border: `1px solid ${theme.palette.primary.dark}`,
                      height: "40px",
                      width: "40px",
                      marginRight: 1,
                    }}
                    onClick={() => undefined}
                  >
                    <HistoryIcon sx={{ color: theme.palette.primary.dark }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
              <ProfileMenu
                handleOpenOfficersModal={handleOpenOfficersModal}
                handleOpenSupportStaffModal={handleOpenSupportStaffModal}
                handleOpenInHouseProviderModal={handleOpenInHouseProviderModal}
                handleOpenDismissModal={handleOpenDismissModal}
                theme={theme}
              />
            </Box>
            <Box mt={2}>
              {isLoadingParticipant ? (
                <Skeleton variant="rectangular" width={300} height={50} />
              ) : (
                <>
                  <Box>
                    <Typography variant="bodyLarge">
                      <strong>{t("client_profile.parca_start_date")}</strong>{" "}
                      {lastEnrollment?.cmStartDate
                        ? dayjs(lastEnrollment?.cmStartDate).format(
                            "DD MMM YYYY"
                          )
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="bodyLarge">
                      <strong>{t("client_profile.officers")}</strong>{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {primaryOfficer?.firstName} {primaryOfficer?.lastName}{" "}
                        {t("client_profile.primary_officer_format")}
                        {lastEnrollment?.additionalOfficersInfo?.length
                          ? ", " +
                            lastEnrollment?.additionalOfficersInfo
                              ?.flatMap((a) => `${a.firstName} ${a.lastName}`)
                              .join(", ")
                          : ""}
                      </span>
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography data-testid="support-staff" variant="bodyLarge">
                      <strong>{t("client_profile.support_staff")}</strong>{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {supportStaff?.length
                          ? supportStaff
                              .flatMap((s) => `${s.firstName} ${s.lastName}`)
                              .join(", ")
                          : "N/A"}
                      </span>
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="bodyLarge">
                      <strong>{t("client_profile.internal_providers")}</strong>{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {internalProviders?.length
                          ? internalProviders
                              .flatMap((i) => `${i.firstName} ${i.lastName}`)
                              .join(", ")
                          : "N/A"}
                      </span>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileCards;
